import { createApp } from "vue"
;(() => {
    const CategoryDetailsApp = createApp({})

    const categoryDetails = document.getElementById("category-details")

    if (categoryDetails) {
        import("@/components/Buttons/AddInterest.vue").then((module) => {
            CategoryDetailsApp.component("add-interest", module.default)
            CategoryDetailsApp.mount("#category-details")
        })
    }
})()
